// Generic imports
import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { Link } from "gatsby"

// Other components imports
import Layout from "components/layout"
import SEO from "components/seo"

// Redux imports
import * as sessionSelectors from "rdx/slices/session/selectors"
import * as sessionThunks from "rdx/slices/session/thunks"

// Static imports

// Utils imports
import { getParameterByName } from "services/utils"

const mapStateToProps = state => ({
  isLoggedIn: sessionSelectors.isLoggedIn(state),
})

const mapDispatchToProps = {
  checkMyself: sessionThunks.checkMyself,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const AccountActivatePage = props => {
  const { isLoggedIn, checkMyself } = props
  const [status, setStatus] = useState(0)

  useEffect(() => {
    const code = getParameterByName("code")
    sessionThunks
      .verifyEmail(code)
      .then(resp => {
        if (isLoggedIn) {
          checkMyself()
        }
        setStatus(1)
      })
      .catch(() => {
        setStatus(2)
      })
  }, [])

  return (
    <Layout>
      <SEO title="Activate Account" />
      <div
        className="layout"
        style={{
          textAlign: "center",
          paddingTop: "20px",
          paddingBottom: "20px",
        }}
      >
        {status === 0 ? (
          <>
            <h2>Activating account...</h2>
          </>
        ) : status === 1 ? (
          <>
            <h2>Activation successful!</h2>
            {isLoggedIn ? (
              <p>
                Complete your registration{" "}
                <Link to="/verification?step=2">here</Link>
              </p>
            ) : (
              <p>Login to complete your registration</p>
            )}
          </>
        ) : (
          <>
            <h2 style={{ paddingBottom: "10px" }}>
              Activation failed. Token is expired
            </h2>
            {isLoggedIn ? (
              <p>
                Resend verification email{" "}
                <Link to="/verification">in this link</Link>
              </p>
            ) : (
              <p>
                Log in to your account and click resend verification email
                button.
              </p>
            )}
          </>
        )}
      </div>
    </Layout>
  )
}

export default withConnect(AccountActivatePage)
